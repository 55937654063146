import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import './App.scss'
import 'toasted-notes/src/styles.css'
import "react-datepicker/dist/react-datepicker.css"
import './style.css'

import './axiosSetup'

import { AuthProvider } from './views/Auth/AuthContext'
import { Loading } from './views/Common/Components'

// Containers
const Layout = React.lazy(() => import('./views/Layout'))

// Pages
const Login = React.lazy(() => import('./views/Auth/LoginPage'))
const EnvInfo = React.lazy(() => import('./views/EnvInfo'))
const ForgotPasswordPage = React.lazy(() => import('./views/Auth/ForgotPasswordPage'))

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <HashRouter>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/forgot-password"
                name="Forgot Password"
                render={(props) => <ForgotPasswordPage {...props} />}
              />
              <Route
                exact
                path="/_env"
                name="Env Info"
                render={(props) => <EnvInfo {...props} />}
              />
              <Route
                path="/"
                name="Dashboard"
                render={(props) => <Layout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </AuthProvider>
    )
  }
}

export default App
