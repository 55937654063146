import axios from 'axios'

const id = x => x
axios.interceptors.response.use(id, error => {
  const { status, data: { message } } = error.response
  if (status === 401 && message === 'Unauthenticated.') {
    window.localStorage.clear()
    window.location.reload()
    return
  }
  if (status === 403) {
    window.alert('Anda tidak mempunyai akses untuk aksi ini')
  }

  throw error
})
