import React from 'react'
import { Spinner } from 'reactstrap'

function Loading(props) {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{minHeight: 300}}>
      <Spinner className="text-muted" />
    </div>
  )
}

export default Loading
