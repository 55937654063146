import React, { useState, useMemo, useContext } from 'react'
import is from 'is_js'

const tokenManager = {
  set(val) {
    window.localStorage.setItem('PENERPOS_TOKEN', val)
  },
  get() {
    return window.localStorage.getItem('PENERPOS_TOKEN')
  },
  remove() {
    window.localStorage.removeItem('PENERPOS_TOKEN')
  }
}

const userManager = {
  set(user) {
    window.localStorage.setItem('PENERPOS_USER', JSON.stringify(user))
  },
  get() {
    return JSON.parse(window.localStorage.getItem('PENERPOS_USER'))
  },
  remove() {
    window.localStorage.removeItem('PENERPOS_USER')
  }
}

const AuthContext = React.createContext()

function AuthProvider(props) {
  const [token, setToken] = useState(tokenManager.get())
  const [user, setUser] = useState(userManager.get())

  const value = useMemo(
    () => ({ user, setUser, token, setToken }),
    [user, token]
  )
  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const authContext = useContext(AuthContext)
  if (!authContext) {
    throw Error('useAuth must be used within AuthProvider')
  }
  const {
    user,
    token,
    setUser,
    setToken,
  } = authContext

  const hasPermission = permission => {
    if (!user) {
      return false
    }

    const permissionNames = user.permissions.map(p => p.permission_name)

    if (is.string(permission)) {
      return permissionNames.includes(permission)
    } else if (is.array(permission)) {
      return permission.some(p => permissionNames.includes(p))
    } else {
      return false
    }
  }

  const isGroupSuperuser = groupName => {
    return groupName === 'superuser'
  }

  const isLoggedIn = () => {
    return is.not.empty(token) && is.not.null(token)
  }
  const persistUser = user => {
    userManager.set(user)
    setUser(user)
  }
  const persistToken = token => {
    tokenManager.set(token)
    setToken(token)
  }
  const logout = () => {
    tokenManager.remove()
    setToken('')
  }

  return {
    isGroupSuperuser,
    isLoggedIn,
    hasPermission,
    user,
    token,
    persistUser,
    persistToken,
    logout
  }
}

export { AuthProvider, useAuth }
